import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import "./styles/ButtonNcr.scss";
const ButtonNcr = (props) => {
    return ( 
        <Button
            {...props}
            className={classNames("btn-ncr",{
                "primary": props.color === "primary",
                "secondary": props.color === "secondary"
            })}
        ></Button>
     );
}
 
export default ButtonNcr;