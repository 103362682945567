import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import 'rsuite/dist/rsuite.min.css';
import Dashboard from "./pages/Dashboard/Dashboard";
import { IntranetContextProvider } from "./context/intranetContext";
import PrivateDashboard from "./pages/Dashboard/PrivateDashboard";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <BrowserRouter>
    <Suspense fallback={<div>loading..</div>}>
      <IntranetContextProvider>
        <Routes>
          <Route path="/Login" element={<Login />} />
          <Route path="/*" element={<PrivateDashboard />} />
        </Routes>
      </IntranetContextProvider>
    </Suspense>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
