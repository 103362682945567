import React, {useState,useEffect } from 'react';
const Context = React.createContext({});
export function IntranetContextProvider({children}){
    const [isLogged, setIsLogged] = useState(()=> window.localStorage.getItem('jwt'));
    const [permisos, setPermisos] = useState([]);
    return <Context.Provider value = {{
        isLogged,
        setIsLogged,
        permisos,
        setPermisos
    }}
    >
    {children}
</Context.Provider>
}
export default Context;