import { useCallback, useContext, useState, useEffect } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import useIntranet from "../../hooks/useIntranet";
import "./styles/Dashboard.scss";
import { Sidenav, Nav } from "rsuite";
import MenuIcon from "@rsuite/icons/Menu";
import logo from "../../assets/img/LOGO_NELCAR.png";
import * as constantes from "../../constants/constantes.js";
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import ToolsIcon from '@rsuite/icons/Tools';
import TagNumberIcon from '@rsuite/icons/TagNumber';
import DocPassIcon from '@rsuite/icons/DocPass';
const Dashboard = () => {
  const { isLogged, setIsLogged } = useIntranet();
  const navigate = useNavigate();
  useEffect(() => {
    isLogged != constantes.TOKEN && navigate("/Login");
  }, [isLogged]);
  //const {setIsLogged} = useIntranet()
  const [expanded, setExpanded] = useState(true);
  const [activeKey, setActiveKey] = useState("0");
  return (
    <div className="it-main">
      <div className="it-topbar">
        <div className="topbar-container">
          <div className="topbar-menu">
            <div className="tb-menu-icon">
              <MenuIcon
                style={{ fontSize: "2rem", color: "#ffffff", cursor:"pointer"}}
                onClick={()=> setExpanded(!expanded)}
              ></MenuIcon>
            </div>
            <div className="tb-menu-logo">
              <img src={logo} height={40}></img>
            </div>
            <div></div>
          </div>
          <div className="topbar-logout">
            <Link
              onClick={() => {
                window.localStorage.removeItem("jwt");
                setIsLogged(null);
                //navigate("/Login")
              }}
            >
              <span style={{ color: "#ffffff", fontWeight: "bold" }}>
                Cerrar sesión
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="it-layout">
        <div className="it-sideNav">
          <Sidenav expanded={expanded} className="sideNav-container">
            <Sidenav.Body>
              <Nav onSelect={setActiveKey}>
                <Nav.Item eventKey="1" icon={<DashboardIcon />}>
                  Inicio
                </Nav.Item>
                <Nav.Item eventKey="2" icon={<ToolsIcon />}>
                  Camionetas
                </Nav.Item>
                <Nav.Item eventKey="3" icon={<TagNumberIcon />}>
                  Facturación
                </Nav.Item>
                <Nav.Item eventKey="4" icon={<DocPassIcon />}>
                  Inventario
                </Nav.Item>
              </Nav>
            </Sidenav.Body>
          </Sidenav>
        </div>
        <div className="it-layout-container"></div>
      </div>
      <div></div>
    </div>
  );
};

export default Dashboard;
