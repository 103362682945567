import * as constantes from "../constants/constantes.js";
const ENDPOINT = constantes.URLAPI;
//const ENDPOINTTEST = constantes.URL_TESTLOGIN;
export default async function loginService ({usuario,contrasena}) {
    return await fetch(`${ENDPOINT}/Login/login`,{
        method: "POST",
        headers:{
            'Content-Type': 'application/json'
        },
       // mode: 'cors',
        
        body:JSON.stringify({usuario, contrasena})
    }).then(res=>{
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                throw new Error("Credenciales no válidas")
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}
