import { useCallback, useContext, useState,useEffect } from 'react';
import {Link, redirect, useNavigate}  from "react-router-dom";
import useIntranet from "../../hooks/useIntranet";
import Dashboard from './Dashboard';
import * as constantes from "../../constants/constantes.js"

const PrivateDashboard = () => {
    const {isLogged} = useIntranet()
    const navigate = useNavigate()
    useEffect(() => {
        isLogged !=  constantes.TOKEN && navigate("/Login")     
    }, []);
    return ( <Dashboard></Dashboard> );
}
 
export default PrivateDashboard;